@import "~bootstrap/scss/bootstrap";

#root {
  min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  min-height: calc(var(--vh, 1vh) * 100);
}

.cta {
  background-color: blanchedalmond;
}

footer {
  margin-top: 25px;
}