.wavesurfer-container {
  margin-top: 12px;
  margin-bottom: 12px;
  width: 100%;
}

region.wavesurfer-region {
  &[data-region-highlight="true"] {
    border: 1px solid rgb(86, 180, 239);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  }
  &::before {
    content: attr(data-region-label);
  }
}